<template>
    <div class="pagination justify-content-end">
        <button type="button" class="rounded-start p-0 border-0" @click="onClickPreviousPage" :disabled="isInFirstPage">
            <span class="page-link">&laquo; Previous</span>
        </button>

        <button type="button" class="p-0 border-0" v-show="currentPage > 2" @click="onClickFirstPage" :disabled="isInFirstPage">
            <span class="page-link">1</span>
        </button>

        <span class="page-link" v-show="currentPage > 2">...</span>
        
        <div v-for="page_no in pages_no" :key="page_no">
            <button type="button" class="p-0 border-0" @click="onClickPage(page_no.name)" :disabled="page_no.isDisabled">
                <span class="page-link text-nowrap" :class="{'page-active': isPageActive(page_no.name)}">{{ page_no.name }}</span>
            </button>
        </div>

        <button type="button" class="p-0 border-0" v-show="currentPage < totalPages - 1" @click="onClickLastPage" :disabled="isInLastPage">
            <span class="page-link">Last</span>
        </button>

        <button type="button" class="rounded-end p-0 border-0" @click="onClickNextPage" :disabled="isInLastPage">
            <span class="page-link">Next &raquo;</span>
        </button>
    </div>
</template>

<style>
    .page-active {
        background-color: #A5966B!important;
        border-color: #6E111D!important;
        color: white!important;
    }
    .page-link {
        color: black;
    }
    .page-link:hover {
        background-color: #6E111D;
        color: white;
    }
</style>

<script>
export default {
  name: 'Pagination',
  data() {
      return {
          load: true
      }
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      if (this.currentPage >= this.totalPages - 3) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage;
    },
    pages_no() {
      const range = [];

      for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons, this.totalPages) ; i++ ) {
        if(i > 0) {
            range.push({
            name: i,
            isDisabled: i === this.currentPage
            });
        }
    }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
        return this.currentPage === page;
    }
  },
}
</script>