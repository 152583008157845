<template>
    <div class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">Events Management</h1>
        <span>Tatsing Group > Events</span>
        <div id="event_table" class="card shadow-sm d-flex flex-column justify-content-center py-4">
            <h5 class="w-100 text-center fw-bold" :class="{'text-success': deleteMsg.toLowerCase().includes('success'),
            'text-danger': !deleteMsg.toLowerCase().includes('success') }" v-show="deleteMsg">
                Event - {{ deleteMsg }}
            </h5>
            <Preloader class="position-absolute w-100 h-100" v-show="isLoading && !$store.state.isLoading" />
            <div class="d-flex w-50 p-3 mx-auto">
                <label for="search" class="border border-end-0 py-2 px-3" style="border-radius: 75px 0 0 75px;">
                    <img src="../../../assets/imgs/magnifying-glass.png" alt="magnifying-glass">
                </label>
                <input v-model="search" type="text" placeholder="Search Event" class="w-100 border border-start-0 py-2 input-outline" style="border-radius: 0 75px 75px 0;" name="event" required>
            </div>
            <table class="table table-hover w-100" id="table1">
                <thead class="border-bottom border-1">
                    <tr style="font-size: small;">
                        <th scope="col" class="py-2" style="width: 25px;"></th>
                        <th scope="col" class="py-2">Event Title</th>
                        <th scope="col" class="py-2">Location</th>
                        <th scope="col" class="py-2">Event Date/Time</th>
                        <th scope="col" class="py-2">Created</th>
                        <th scope="col" class="py-2">Actions</th>
                        <th scope="col" class="py-2" style="width: 25px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="event in $store.state.event.event_data.data" :key="event">
                        <td style="border-style: none;"></td>
                        <td style="border-style: none;"> {{ event.title }} </td>
                        <td style="border-style: none;"> {{ event.location }} </td>
                        <td style="border-style: none;"> {{ change_format(event.event_date_time) }} </td>
                        <td style="border-style: none;"> {{ change_format(event.created) }} </td>
                        <td style="border-style: none;">
                            <div class="d-flex">
                                <button type="button" class="mb-1 border-0 bg-transparent" 
                                    @click="edit(event.id)" v-if="$store.getters['get_perm']('updateEvent')">
                                    <img src="../../../assets/imgs/icons/edit.png" name="edit-button-icon" alt="edit" width="15">
                                </button>
                                <button type="button" class="border-0 bg-transparent"
                                    @click="deleteModal(event.id)" v-if="$store.getters['get_perm']('deleteEvent')">
                                    <i class="bi bi-trash-fill text-theme" style="width: 15px;"></i>
                                </button>
                            </div>
                        </td>
                        <td style="border-style: none;"></td>
                    </tr>
                </tbody>
            </table>
            <Pagination class="mx-2" :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" :maxVisibleButtons="4" @pagechanged="onPageChange" />
        </div>

        <Modal v-if="$store.getters['get_perm']('deleteEvent')">
            <template v-slot:head>
                <h5 class="modal-title">Delete Event</h5>
                <button type="button" class="close" @click="closeModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </template>
            <template v-slot:main>
                <div>
                    <h4 style="font-size: large;">Do you want to delete <strong>{{ to_deleteEvent.title }}</strong>?</h4>
                    <h4 style="font-size: large;">Location: <strong>{{ to_deleteEvent.location }}</strong></h4>
                    <h4 style="font-size: large;">Location: <strong>{{ to_deleteEvent.time }}</strong></h4>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="bg-secondary-theme text-white p-1 rounded" style="width:75px;" @click="closeModal">Close</button>
                <button type="button" class="bg-theme text-white p-1 rounded" style="width:75px;" @click="del(to_deleteEvent.id)">Delete</button>
            </template>
        </Modal>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import Pagination from "../../../components/Pagination.vue";
import Preloader from "../../../components/Preloader.vue";
import Modal from "../../../components/Modal.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('tablecontroller', 'event')
import { format } from 'date-fns/fp'

export default {
  name: 'Event',
  components: {
    Pagination,
    Preloader,
    Modal
  },
  data() {
    return {
        table_option: {
            search: this.search,
            sort_by: this.sort_by,
            sort_dir: this.sort_dir,
            perPage: this.perPage,
            currentPage: 1
        },
        to_deleteEvent: {},
        deleteMsg: '',
        visitor_stats: {}
    }
  },
  async mounted() {
      await this.$store.dispatch('event/getEvents', this.table_option)
  },
  methods: {
    change_format(date) {
      var date_time = new Date(date)
      return format('MMM. dd, yyyy eee | hh:mm a', date_time)
    },
    onPageChange(page) {
      this.table_option.currentPage = page;
      this.$store.dispatch('event/getEvents', this.table_option);
    },
    sortBy(value) {
        this.table_option.sort_by = value
        this.table_option.sort_dir = this.table_option.sort_by === value 
            ? (this.table_option.sort_dir === 'desc' ? 'asc' : 'desc') 
            : 'desc'
        this.$store.dispatch('event/getEvents', this.table_option);
    },
    edit(id) {
        const data = Object.values(this.$store.state.event.event_data.data).find((key) => key.id == id)
     
        this.$store.commit({
            type: 'event/SET_SINGLE_EVENT_DATA',
            data: data
        })
        this.$router.push({ path: '/create-event', query: { id: id } })
    },
    closeModal() {
        document.getElementById('modal-container').style.display = "none";
    },
    async deleteModal(id) {
        document.getElementById('modal-container').style.display = "block";

        const obj = Object.values(this.$store.state.event.event_data.data)

        const found_obj = obj.find((key) => key.id === id)

        Object.assign(this.to_deleteEvent, found_obj)
    },
    async del(id) {
      await this.$store.dispatch('event/deleteEvent', id).then(() => {
        this.deleteMsg = this.$store.state.event.message
        this.closeModal()
          
        this.$store.dispatch('event/getEvents', this.table_option);
        
        document.getElementById('event_table').scrollIntoView()
      })
    },
  },
  computed: {
    search: {
      get() {
        return this.table_option.search ?  this.table_option.search : this.$store.state.tablecontroller.search;
      },
      set(value) {
        this.table_option.search = value
        this.table_option.currentPage = 1
        this.$store.dispatch('event/getEvents', this.table_option);
      },
    },
    sort_by: {
      get() {
        return this.table_option.sort_by ?  this.table_option.sort_by : this.$store.state.tablecontroller.sort_by;
      }
    },
    sort_direction: {
      get() {
        return this.table_option.sort_dir ?  this.table_option.sort_dir : this.$store.state.tablecontroller.sort_dir;
      },
      set(value) {
        this.table_option.sort_dir = value
        this.$store.dispatch('event/getEvents', this.table_option);
      },
    },
    perPage: {
      get() {
        return this.table_option.perPage ?  parseInt(this.table_option.perPage) : this.$store.state.tablecontroller.perPage;
      },
      set(value) {
        this.table_option.perPage = value
        this.$store.dispatch('event/getEvents', this.table_option);
      }
    },
    totalPages: {
        get() {
            return this.$store.state.event.totalPages
        }
    },
    currentPage: {
        get() {
            return this.$store.state.event.currentPage
        }
    },
    isLoading: {
        get() {
            return this.$store.state.event.isLoading
        }
    },
  },
}
</script>